import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(1);
    public data$: Observable<any> = this.dataSubject.asObservable();
  
    setData(data: any) {
      this.dataSubject.next(data);
    }
}